import { graphql, Link } from "gatsby"
import React from "react"
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../components/seo'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
const baseuri = "https://dmf5.xyz"
const baseuridemo = "http://admin.hovinx.com"

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteUrl = data.site.siteMetadata.siteUrl
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.news
    const { currentPage, numPages } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPages
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${siteTitle} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
          description={`${siteDescription} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
          canonical={`https://${domainName}`}
        />
        <div className="row">
          <div className="col-lg-8">
            <div className="row equal">
              {posts.map(node => {
                const title = node.title || node.slugtitle
                return (
                  <div className="col-lg-12 col-md-12 psdd" key={node.slugtitle}>
                    <article className="row post-id-single">
                      <div className="col-lg-6 col-md-6 post-id-single-img">
                        <a className="post-id-single-link" tabIndex="-1" href={siteUrl + "/" + node.slugtitle} title={title}>
                          <figure className="post-id-single-figure">
                            <LazyLoadImage
                              className="post-id-single_image"
                              alt={title}
                              title={title}
                              src={node.image}
                              effect="blur"
                            />
                          </figure>
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 post-id_blurb">
                        <a className="post-id_link" href={siteUrl + "/" + node.slugtitle}>
                          <h2 className="post-id_headline-with-image">
                            {title}
                          </h2>
                        </a>
                        <div className="d-flex justify-content-start post-id_card">
                          <div className="post-id_image-row">
                            <div className="post-id_image-row-item">
                              <LazyLoadImage
                                className="post-id_image post-id_image-small"
                                title={node.profileName}
                                src={node.profileImage}
                                effect="blur"
                              />
                            </div>
                          </div>
                          <div className="post-id_name">
                            <Link to={`/authors/${node.profileSlugName}`}>{node.profileName}</Link>
                            <div className="post-id_published">
                              <time>{moment(node.publishDate).format('DD.MM.YYYY')}</time>
                            </div>
                          </div>
                        </div>
                        <div className="post-id_desc">
                          <div className="post-id_subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                        </div>
                        
                      </div>
                    </article>
                  </div>
                )
              })}
            </div>
          </div>
          <Sidebar/>
        </div>
        <div className="row d-flex justify-content-center pagi-wrap">
          {!isFirst && (
            <div className="pagi__link">
              <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                ←
                </Link>
            </div>
          )}
          {Array.from({ length: numPages }, (_, i) => {
            if (i < curr + 3 && i > curr - 3) {
              return (
                <div
                  key={`pagination-number${i + 1}`}
                  className="pagi__link"
                >
                  <Link
                    to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                    className={i + 1 === curr ? "current" : "na-pagi"}
                  >
                    {i + 1}
                  </Link>
                </div>
              )
            }
          })}
          {!isLast && (
            <div className="pagi__link">
              <Link to={`/page/${nextPage}`} rel="next">
                →
                </Link>
            </div>
          )}
        </div>

      </Layout>
    )
  }
}
export default BlogIndex
export const query = graphql`
query ($limit: Int = 21, $skip: Int = 0, $siteId: Int = 1083) {
  site {
    siteMetadata {
      title
      domainName
      description
      siteUrl
    }
  }
  privateGraphql {
    news(
      limit: $limit 
      skip: $skip,
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
